import React, { useState } from "react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// import Container from "common/components/UI/Container";
// import male1bafore from "../../../common/assets/image/interior/21.png";
// import male1after from "../../../common/assets/image/interior/23.png";
// import male2before from "../../../common/assets/image/interior/25.png";
// import male2after from "../../../common/assets/image/interior/31.png";
// import femalebefore from "../../../common/assets/image/interior/33.png";
// import femaleafter from "../../../common/assets/image/interior/10.png";

// import male3bafore from "../../../common/assets/image/interior/11.png";
// import male3after from "../../../common/assets/image/interior/12.png";
// import male4before from "../../../common/assets/image/interior/13.png";
// import male4after from "../../../common/assets/image/interior/14.png";
// import male5before from "../../../common/assets/image/interior/18.png";
// import male5after from "../../../common/assets/image/interior/2.png";

import { SectionHeader } from "../global.style";
import Fade from "react-reveal/Fade";
import Heading from "common/components/Heading";
import SectionWrapper from "./slider.style";
// import { border, margin, marginBottom, marginTop } from "styled-system";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

// Styles for the modal
const customStyles = {
  content: {
    position: "absolute",
    inset: "50% auto auto 50%",
    overflow: "visible",
    borderRadius: "10px",
    border:"2px solid #bc8941",
    outline: "none",
    padding: "3px",
    marginRight: "-50%",
    margin:"0px",
    transform: "translate(-50%, -50%)",
    maxWidth: "48%",
    maxHeight: "80%",
  },
};

const customClose = {
  background: "white",
  position: "absolute",
  color: "#000000",
  borderRadius: "60px !important",
  borderRadius: "50px",
  right: "-9px",
  width: "25px",
  padding: "0",
  zIndex: "11111",
  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
}

function Slider() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const images = [
    '/before-after/2.webp',
    '/before-after/10.webp',
    '/before-after/11.webp',
    '/before-after/12.webp',
    '/before-after/13.webp',
    '/before-after/14.webp',
    '/before-after/18.webp',
    '/before-after/21.webp',
    '/before-after/23.webp',
    '/before-after/25.webp',
    '/before-after/31.webp',
    '/before-after/33.webp',
  ];

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  const swiperBreakpoints = {
    // When viewport width is less than or equal to 767px (typical mobile size)
    767: {
      slidesPerView: 3, // Display 2 slides on mobile
      spaceBetween: 10, // Adjust as needed
    },
    // When viewport width is greater than 767px (larger screens)
    768: {
      slidesPerView: 3, // Display 1 slide on larger screens
      spaceBetween: 20, // Adjust as needed
    },
  };

  return (
    <SectionWrapper id="successStories">
      <Fade top>
        <SectionHeader>
          <Heading as="h5" content="Life-changing results" style={{marginBottom: '0'}} />
          <Heading content="Before and after success stories" />
        </SectionHeader>
      </Fade>
      <div className="myContainer homeSlider flexDirecColumn">
        <Fade bottom>
          <Swiper
            spaceBetween={10} // Adjust as needed
            slidesPerView={1} // Display 4 slides per view
            loop={true}
            navigation
            autoplay={{ delay: 3000 }}
            breakpoints={swiperBreakpoints}
            // pagination={{ clickable: true }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="flexCard" onClick={() => openModal(image)}>
                  <div className="SScard">
                    <img src={image} alt={`Slide ${index + 1}`} />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Fade>
      </div>
      {/* Modal for displaying the selected image */}
      <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Image Modal"
    >
      <div className="modal-header">
        <button className="close-button" onClick={closeModal} style={customClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      {/* Image Slider */}
      <Swiper
        spaceBetween={10} // Adjust as needed
        slidesPerView={1} // Display 1 slide per view
        navigation
        loop={true}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Modal>
    </SectionWrapper>
  );
}

export default Slider;
